import React from "react"
import { connect } from "react-redux"

import DocContainer from "./DocContainer"
import Nav from "./nav/Nav"
import Content from "./content/Content"
import CodePanel from "./content/CodePanel"

import DocHeader from "./DocHeader";
import Breadcrumb from './Breadcrumb';
import SiteNotificationCtrl from "../../../widgets/microsite/siteNotification/SiteNotificationCtrl"

export class Doc extends React.Component {
    onStartLoadOrphenLink = (url) => {
        this.props.dispatch({
            type: 'FETCH_STATIC_ORPHEN_LINK',
        })

        fetch(url)
            .then((e) => e.text())
            .then(data => {
                this.props.dispatch({
                    type: 'FETCH_STATIC_ORPHEN_LINK_FULFILLED',
                    payload: { 
                      url: url,
                      data: massageOrphanHtml(url, data)
                    },
                })
          }) 
    }
    onOrphenLinkGoback = () => {
        this.props.dispatch({
            type: 'ORPHEN_LINK_GOBACK',
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selected.data && this.props.selected.data && prevProps.selected.data !== this.props.selected.data) {
            try {
                const _event = new CustomEvent("load", {
                    detail: {
                        data: {
                            ...this.props,
                            content: this.props.selected.data,
                            container: this.refs.doc.getWrappedInstance().refs["root"]
                        }
                    },
                    bubbles: true,
                    cancelable: true
                });

                if (this.instance) {
                    this.instance.container.dispatchEvent(_event)
                }
            } catch (e) {  }
        }
    }

    render() {
        const { items, config, meta } = this.props;

        if (!items || !meta || !config) {
            return <div className="overlay">
                <div className="loading-img"></div>
            </div>;
        }

        return <DocContainer {...this.props} ref="root">
            {meta && meta.deprecated_banner && (
              <div className="pubhub-widgets">
                <SiteNotificationCtrl type="danger" description={meta.deprecated_banner} />
              </div>
            )}
            <DocHeader {...this.props} />
            {config.useBreadcrumb && <Breadcrumb config={config} meta={meta} />}
            <Nav {...this.props} />
            <CodePanel />
            <Content instance={this.instance} {...this.props} selected={{...this.props.selected}} 
              onStartLoadOrphenLink={this.onStartLoadOrphenLink} 
              onOrphenLinkGoback={this.onOrphenLinkGoback}
              ref="doc" />
        </DocContainer>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        items: state.items,
        meta: state.meta,
        selected: state.selected,
        config: state.config,
        search: state.search
    };
}

function massageOrphanHtml(htmlUrl, htmlContent) {
  const pattern = /<img\s+[^>]*src=["']([^"']+)["']/g;
  function replaceSrc(match, src) {
    if (src.startsWith("http://") || src.startsWith("https://") || src.startsWith("/")) {
      return match;
    }
    return match.replace(src, resolveUrl(src, htmlUrl));
  }

  return (htmlContent || '').replace(pattern, replaceSrc);
}

function resolveUrl(relativePath, baseUrl) {
  const isSupportURL = window.URL && (typeof window.URL === 'function');
  if (isSupportURL) {
    return new URL(relativePath, baseUrl).href
  }

  const urlParts = baseUrl.split('/');

  urlParts.pop(); // remove the html file name
  const parts = relativePath.split('/');

  for (let i = 0; i < parts.length; i++) {
    if (parts[i] === "..") {
      urlParts.pop();
    } else if (parts[i] !== ".") {
      urlParts.push(parts[i]);
    }
  }

  return urlParts.join('/');
}


export default connect(mapStateToProps, null, null, { withRef: true })(Doc);
