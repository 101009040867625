import React, { createRef } from "react";
import DocLabel from './docLabel';

import "./index.scss";
import { loadJSFile } from "../../../common/utility/utils";

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.searchNode = createRef(null);
        this.handleScroll = this.handleScroll.bind(this);

        this.state = {
            top: 0,
        };
    }

    componentDidMount() {
        const { config } = this.props;
        if (config.enableAI) {
            window.addEventListener('scroll', this.handleScroll, { passive: true });
        }
        this.handleScroll();
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.enableAI && !window.AiSearch && nextProps.config.enableAI) {
            // console.log("Need loading AiSearch");
            // todo: this is hardcoded, need to be fixed before prod
            let dwcFile = 'https://static.testing.devnetcloud.com/dwc/dwc-ai-search/latest/index.js';
            if (window.location.host == 'integration-developer.cisco.com') {
                dwcFile = 'https://devnetapps.cisco.com/dwc/dwc-ai-search/latest/index.js'
            } else if (window.location.host == 'developer.cisco.com') {
                dwcFile = 'https://static.production.devnetcloud.com/dwc-ai-search/index.js'
            }
            loadJSFile(dwcFile, { async: true }).then(() => {
                console.log('retries---', window.AiSearch);
            }).catch((error) => {
                    console.error(error);
            });
            window.addEventListener('scroll', this.handleScroll, { passive: true });
        }
    }
    componentWillUnmount() {
        const { config } = this.props;
        if (config.enableAI) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll() {
        const { top } = this.state;
        const { current } = this.searchNode;

        if (current) {
            const rect = current.getBoundingClientRect();
            if (top !== rect.top) {
                this.setState({ top: rect.top });
            }
        }
    }

    render() {
        // will switch to metadata tool later
        const { meta, config } = this.props;
        const { top } = this.state;

        const arrow = ">";
        let title = meta ? meta.name : "";
        let breadcrumb = null;
        if (config && config.breadcrumb) {
            breadcrumb = config.breadcrumb;
        }

        let product = '';
        if (meta && meta.products) {
            product = meta.products.split(',')[0];
        }

        return (
            <section className="doc-breadcrumb">
                <dwc-ai-search top={`${top}px`} />
                <nav className="doc-breadcrumb__container">
                    <ol className="doc-breadcrumb__links">
                        {breadcrumb ?
                            breadcrumb.map((nav, i) => (
                                <li>
                                    {i > 0 && <i className="doc-breadcrumb__arrow" />}
                                    {nav.url ? (
                                        <a className="doc-breadcrumb__link" href={nav.url}>{nav.title}</a>
                                    ) : nav.title}
                                </li>
                            )
                            ) : (
                                <>
                                    <li>
                                        <a className="doc-breadcrumb__link" href="/docs/">Documentation</a>
                                        <i className="doc-breadcrumb__arrow" />
                                    </li>
                                    <li>
                                        <a className="doc-breadcrumb__link" href="/docs/search/">All</a>
                                        <i className="doc-breadcrumb__arrow" />
                                    </li>
                                    {product && (
                                        <li>
                                            <a className="doc-breadcrumb__link" href={`/docs/search/?products=${encodeURIComponent(product)}`}>{product}</a>
                                            <i className="doc-breadcrumb__arrow" />
                                        </li>
                                    )}
                                    <li>{title}</li>
                                </>
                            )}
                    </ol>
                    <DocLabel config={config} />
                    {config.enableAI && (
                        <div ref={this.searchNode} className="doc-breadcrumb__search gradual-border">
                            <i className="doc-breadcrumb__search-icon" />
                            <span
                                className="doc-breadcrumb__search-text"
                                onClick={() => {
                                    if (window.AiSearch) this.handleScroll(); window.AiSearch.open();
                                }}
                            >
                                Search docs
                            </span>
                            <span class="doc-breadcrumb__search-tag">BETA</span>
                        </div>
                    )}
                </nav>
            </section>
        )
    }
}
