import React from "react";

export function shouldUseWebComponent(type) {
    return ["oas-meta", "oas-api", "oas-model", "oas3-meta", "oas3-api", "oas3-model"].indexOf(type) != -1
}

function deepReplaceSpec(spec, parent, basePath = '') {
    let target = Array.isArray(spec) ? [...spec] : { ...spec };
    Object.keys(target).forEach((key) => {
        if (key === '$ref' || key === '$$ref') {
            const modelNmae = target[key].replace(/#.*\//, '');
            const modelList = parent && parent.items[2];
            let externalDocs = undefined
            if (modelList && modelList.title === 'Model' && modelList.items) {
                const modelLink = modelList.items.find((item) => item.title === modelNmae);
                if (modelLink) externalDocs = { url: basePath + modelLink.url };
            }
            target = ({
                ...target,
                externalDocs,
            });
        }
        if (key === 'links') {
            Object.values(target[key]).forEach((link) => {
                if (link.operationId && link.url) {
                    const node = findOASByKey(parent, link.url, 'content');
                    if (node) {
                        link.operationRef = basePath + node.url;
                    }
                }
            });
        }
        if (typeof target[key] === 'object') {
            target[key] = deepReplaceSpec(target[key], parent, basePath);
        }
    });

    return target;
}

function findOASByKey(item, url, key = 'url') {
    const { items } = item;
    if (!items) return null;

    let target = null;
    items.forEach((sub) => {
        if (target) return;
        if (sub[key] === url) {
            target = sub;
            return;
        }
        if (sub.items) {
            target = findOASByKey(sub, url, key);
        }
    });

    return target;
}

function findOASParent(items, url) {
    let parent = null;
    items.forEach((item) => {
        if (parent) return;
        if (item.type === 'oas3' || item.type === 'oas') {
            const hasChild = findOASByKey(item, url);
            if (hasChild) parent = item;
            return;
        }
        if (item.items) {
            parent = findOASParent(item.items, url);
        }
    });

    return parent;
}

export class WebComponent extends React.Component {
    constructor(props) {
        super(props);
        this.setSwaggerSpec = this.setSwaggerSpec.bind(this);
        this.findOASParent = this.findOASParent.bind(this);
        this.getUpdatedData = this.getUpdatedData.bind(this);
    }

    findOASParent() {
        const { items, selected } = this.props;
        return findOASParent(items, selected.itemURL);
    }

    getUpdatedData() {
        const { router } = this.props;
        let { data } = this.props;
        const basePath = router.baseURL ? router.baseURL : '#!'

        if (typeof data === 'string') {
            try {
                data = JSON.parse(data);
            } catch (e) {
                data = {}
                console.error(data, e);
            }
        }

        const { spec } = data;

        return {
            ...data,
            spec: deepReplaceSpec(spec, this.findOASParent(), basePath)
        };
    }

    setSwaggerSpec() {
        const { selected } = this.props;
        const type = selected.item.type;

        if (shouldUseWebComponent(type)) {
            const el = document.getElementById("swagger_spec");
            if (el) {
                el.setAttribute("spec", JSON.stringify(this.getUpdatedData()));
            }
        }
    }
    componentDidMount() {
        this.setSwaggerSpec();
    }

    componentDidUpdate() {
        this.setSwaggerSpec();
    }

    render() {
        const { selected } = this.props;
        const type = selected.item.type;

        if (type.startsWith("oas3-")) {
            const tag = type.replace("oas3-", "dui-swagger-") + '-v3';
            return this.renderWidget(tag);
        }

        if (type.startsWith("oas-")) {
            const tag = type.replace("oas-", "dui-swagger-"); //hacky, oas-meta -> dui-swagger-meta
            return this.renderWidget(tag);
        }

        return '';
    }

    renderWidget(tag) {
        const html = `<${tag} id="swagger_spec">
            <style>@media (max-width: 768px) {.swagger-container { height: auto; } }</style>
        </${tag}>`;
        return <div dangerouslySetInnerHTML={{ __html: html }}></div>
    }
}
